import AccountSettings from "app/views/AccountSettings/AccountSettings";
// import CompanyDetails from "app/views/Companies/CompanyDetail";

const CompanyRoutes = [
  {
    path: "/account-settings",
    component: <AccountSettings />,
  },
  // {
  //   path: "/company-details",
  //   component: <CompanyDetails />,
  // },

];

export default CompanyRoutes