import React, { Fragment, useEffect, useState } from 'react'
import { Box, Chip, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { DeleteTwoTone, EditTwoTone, KeyboardArrowLeft, KeyboardArrowRight, PersonAddAltTwoTone, Queue } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Layout from 'app/layout/Layout'
import useAuth from 'app/hooks/useAuth'
import ValidationDialog from 'app/components/Dialog/ValidationDialog'
import RegisterByBulk from 'app/components/Dialog/RegisterByBulk'
import AddByBulk from 'app/components/Dialog/AddByBulk'
import AddNewEmployee from 'app/components/Dialog/AddNewEmployee'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import { Loading } from 'app/assets/Images'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import EmployeeFilter from 'app/components/Filters/EmployeeFilter'
import UsersServices from 'app/apis/Users/UsersServices'


// *import components


// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});


function EmployeeDetails() {

  const { companyId, role } = useAuth()

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsData, setRowsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedEmployeeIDReset, setSelectedEmployeeIDReset] = useState("");
  const [selectedSwitch, setSelectedSwitch] = useState([]);
  const [addBulkDialog, setAddBulkDialog] = useState(false);
  const [registerBulkDialog, setRegisterBulkDialog] = useState(false);
  const [addEmployeeDialog, setAddEmployeeDialog] = useState(false);
  // for type edit modal
  const [editEmployeeDialog, setEditEmployeeDialog] = useState(false);
  // *for loader
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [validationDialog, setValidationDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *For Get Employees
  const getEmployees = async () => {
    try {
      let response
      if (companyId) {
        response = await UsersServices.getEmployees(companyId)
      } else {
        response = await UsersServices.getEmployeesAdmin()
      }
      const { status, responseCode, data } = response
      if (status === true && responseCode === 200) {
        setLoaderStatus(false)
        setRowsData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For update employee
  const updateEmployeeStatus = async (data) => {
    try {
      let obj = {
        id: data[0],
        is_active: data[1] ? false : true,
      }
      const { status, responseCode, message } = await UsersServices.updateEmployee(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        setSelectedItem([])
        getEmployees()
      }

    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For reset employees
  const resetPassword = async (employeeID) => {
    try {
      let obj = {
        id: employeeID
      }
      const { status, responseCode, message } = await UsersServices.resetPassword(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message.message)
        setSelectedEmployeeIDReset("")
        getEmployees()
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // handle modal
  const handlePromise = (myValue) => {
    if (myValue) {
      if (selectedEmployeeIDReset) {
        resetPassword(selectedEmployeeIDReset)
      }
      else {
        updateEmployeeStatus(selectedSwitch)
      }
      setValidationDialog(false)
    }
    else {
      setValidationDialog(false)
    }
  }

  // for get employees by id
  const getUserByID = async (id) => {
    try {
      const { status, responseCode, message } = await UsersServices.getUserByID(id)
      if (status === true && responseCode === 200) {
        setSelectedItem(message.data)
        setAddEmployeeDialog(!addEmployeeDialog);
        setEditEmployeeDialog(true)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }


  useEffect(() => {
    getEmployees()
  }, [])


  return (
    <Layout>

      {/* ======= validation dialog =====  */}
      <ValidationDialog message={validationMessage} handlePromise={handlePromise} open={validationDialog} handleClose={() => setValidationDialog(!validationDialog)} />

      {/* Add register by bulk dialog */}
      <RegisterByBulk open={registerBulkDialog} handleClose={() => setRegisterBulkDialog(!registerBulkDialog)} />

      {/* Add by bulk dialog */}
      <AddByBulk open={addBulkDialog} handleClose={() => setAddBulkDialog(!addBulkDialog)} />

      {/* Add by new employee */}
      <AddNewEmployee
        getEmployees={getEmployees}
        toggle={editEmployeeDialog}
        toggleFunction={() => setEditEmployeeDialog(false)}
        selectedItem={selectedItem}
        open={addEmployeeDialog}
        handleClose={() => { setSelectedItem({}); setAddEmployeeDialog(!addEmployeeDialog) }}
      />

      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Employees List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Employees']} />
          </Grid>
        </Grid>

        {loaderStatus ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>

            {/* Employee Filter */}
            <EmployeeFilter />

            <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", mt: 2 }}>

              <Box
                onClick={() => setAddEmployeeDialog(!addEmployeeDialog)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 1,
                  cursor: "pointer",
                  color: colors.primary, bgcolor: colors.primaryLight,
                }}>
                <PersonAddAltTwoTone sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px" }} > Add New Employee</Typography>
              </Box>

              <Box
                onClick={() => setRegisterBulkDialog(!registerBulkDialog)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 1,
                  ml: 1,
                  cursor: "pointer",
                  color: colors.primary, bgcolor: colors.primaryLight,
                }}>
                <Queue sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px" }} >Register by Bulk</Typography>
              </Box>
              <Box
                onClick={() => setAddBulkDialog(!addBulkDialog)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 1,
                  ml: 1,
                  cursor: "pointer",
                  color: colors.primary, bgcolor: colors.primaryLight,
                }}>
                <Queue sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px" }} >Add by Bulk</Typography>
              </Box>
            </Box>

            <TableContainer component={Paper} sx={{
              '&::-webkit-scrollbar': {
                bgcolor: colors.white,
                height: "8px",
                borderRadius: "10px",
                cursor: "pointer"
              },
              '&::-webkit-scrollbar-thumb': {
                bgcolor: colors.primary,
                borderRadius: "10px",
                cursor: "pointer"
              },
              "&.MuiPaper-root": {
                borderRadius: "12px",
                mt: 2,
                boxShadow: 0,
              }
            }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    <TableCell sx={{ fontWeight: "bold" }} >#ID</TableCell>
                    {role === "admin" &&
                      <TableCell sx={{ fontWeight: "bold" }} >Company</TableCell>
                    }
                    <TableCell sx={{ fontWeight: "bold" }} >Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Department</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Phone No.</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                    {/* <TableCell sx={{ fontWeight: "bold" }}>Cnic Number</TableCell> */}
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Password</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0 ? rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rowsData).map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {row.id}
                      </TableCell>
                      {role === "admin" &&
                        <TableCell >
                          <Typography variant="body2" color="initial" noWrap>{row.company?.name}</Typography>
                        </TableCell>
                      }
                      <TableCell >
                        <Typography variant="body2" color="initial" noWrap>{row.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.department ? row.department : "no department"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.phone ? row.phone : "no phone"}</Typography>
                      </TableCell>
                      <TableCell>
                        {/* <Typography variant="body2" color="initial" noWrap>{row.email}</Typography> */}
                        <CustomTooltip title={row.email} arrow>
                          <Typography variant="body2" color="initial" noWrap
                            sx={{ cursor: "default", maxWidth: 170 }}>{row.email}</Typography>
                        </CustomTooltip>
                      </TableCell>
                      {/* <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.nic ? row.nic : "no cnic number"}</Typography>
                      </TableCell> */}
                      <TableCell >
                        <Switch size="small"
                          checked={row.is_active}
                          onChange={() => { setSelectedSwitch([row.id, row.is_active]); setValidationDialog(!validationDialog); setValidationMessage("Are you sure you want to change status") }}
                        />
                      </TableCell>
                      <TableCell >
                        <Chip
                          onClick={() => { setSelectedEmployeeIDReset(row.id); setValidationDialog(!validationDialog); setValidationMessage("Are you sure you want to reset password") }}
                          size='small' label="Reset & Resend" color="info" variant='outlined' sx={{
                            fontSize: "12px", cursor: "pointer", ":hover": {
                              bgcolor: colors.bondiBlueLight, color: colors.secondaryDark
                            }
                          }} />
                      </TableCell>
                      <TableCell >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Edit" >
                            <IconButton
                              onClick={() => { getUserByID(row.id); }}
                            >
                              <EditTwoTone color="secondary" sx={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" >
                            <IconButton >
                              <DeleteTwoTone color="error" sx={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>

            <Table sx={{ marginTop: "12px" }} >
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={rowsData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Layout>
  )
}

export default EmployeeDetails