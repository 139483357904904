import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CloseRounded } from '@mui/icons-material';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { hexColorRegex } from 'app/utils';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster } from 'app/components/Toaster/Toaster';

function VehicleColorDialog({ open, close, add, update, loading, updateData }) {

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  // *For Submit Form
  const submitForm = async (formData) => {
    try {
      let obj = {
        color: formData.colorName,
        hex_code: formData.colorCode
      }
      if (updateData) {
        obj.old_color = updateData.color
        update(obj)
      } else {
        add(obj)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (updateData) {
      setValue("colorName", updateData.color)
      setValue("colorCode", updateData.hex_code)
    }
  }, [updateData])

  return (
    <Dialog open={open} fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}>
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Vehicle Color
        <IconButton onClick={() => { close(); reset() }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent >
        <Typography component={"form"} onSubmit={handleSubmit(submitForm)}>
          <Grid container columnSpacing={2} >
            <Grid item xs={6}>
              <InputField
                label="Color Name"
                error={errors?.colorName?.message}
                register={register("colorName", {
                  required: 'Please enter color name.'
                })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Color Code"
                error={errors?.colorCode?.message}
                register={register("colorCode", {
                  required: 'Please enter color code.',
                  pattern: {
                    value: hexColorRegex,
                    message: 'Color code must be a hex',
                  }
                })
                }
              />
            </Grid>
          </Grid>
          <PrimaryButton
            data-testId="submit-btn"
            title="Submit"
            type="submit"
            loading={loading}
            fullWidth={true}
          />
        </Typography>

      </DialogContent>
    </Dialog>
  )
}

export default VehicleColorDialog