import BookingDetail from "app/views/Bookings/BookingDetail";
import Bookings from "app/views/Bookings/Bookings";
import AddCompany from "app/views/Companies/AddCompany";
import Companies from "app/views/Companies/Companies";
import ViewCompany from "app/views/Companies/ViewCompany";
import Passengers from "app/views/Passengers/Passengers";
import VehicleManagement from "app/views/VehicleManagement/VehicleManagement";
import Vehicles from "app/views/Vehicles/Vehicles";

const AdminRoutes = [
  {
    path: "/companies",
    component: <Companies />,
  },
  {
    path: "/vehicle-management",
    component: <VehicleManagement />,
  },
  {
    path: "/vehicles",
    component: <Vehicles />,
  },
  {
    path: "/company/add-company",
    component: <AddCompany />,
  },
  {
    path: "/company-information/:id",
    component: <ViewCompany />,
  },
  {
    path: "/bookings",
    component: <Bookings />,
  },
  {
    path: "/booking-detail/:id",
    component: <BookingDetail />,
  },
  {
    path: "/passengers",
    component: <Passengers />,
  },
];

export default AdminRoutes