import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, InputAdornment, IconButton, Divider, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import useAuth from 'app/hooks/useAuth';
import AuthServices from 'app/apis/Auth/AuthServices';
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';
import { emailRegex } from 'app/utils';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { InputField, OutlineInputField } from 'app/components/UI/TextField';
import { PrimaryButton } from 'app/components/Buttons/Button';

function Login() {

  const { login } = useAuth();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);

  // *Login 
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData.email,
        password: formData.password
      }
      const { status, responseCode, message, data } = await AuthServices.login(obj)
      if (status === true && responseCode === 200) {
        login(data)
        SuccessToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <Grid container alignItems={'center'} sx={{ height: "100vh" }}>

      <Grid item xs={12} md={6}>
        <Box sx={{ p: 3, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <img src={Images.logo} width="30%" alt="logo" />
          <Box>
            <img src={Images.register} width="70%" alt="login" />
          </Box>
          <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold" }}> Welcome</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{
          boxShadow: "rgba(48,113,85,0.4) 0px 48px 100px 0px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }} >
          <Box sx={{ textAlign: "center", p: 3, borderRadius: 2, width: .7, boxSizing: "border-box" }}>
            <Typography variant="h4" sx={{ color: colors.black, my: 2 }}>
              Login
            </Typography>
            <Divider sx={{ mb: 1.5, bgcolor: colors.primaryLight }} />
            <Typography component={"form"} onSubmit={handleSubmit(loginInfo)}>
              <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <InputField
                  label="Email"
                  error={errors?.email?.message}
                  register={register("email", {
                    required: 'Please enter your email.',
                    pattern: {
                      value: emailRegex,
                      message: 'Please enter a valid email address',
                    }
                  })}
                />
                <OutlineInputField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.password?.message}
                  register={register("password", {
                    required: 'Please enter password.',
                  })
                  }
                />
              </Box>
              <PrimaryButton
                data-testId="login-btn"
                title="Login"
                type='submit'
                loading={loading}
                fullWidth={true}
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;