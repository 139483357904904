import { CarRepairTwoTone, DisplaySettings, DynamicForm, Menu, PermIdentity, Settings } from "@mui/icons-material";
import { BsSpeedometer2 } from 'react-icons/bs';

const Navigation = [
  {
    label: 'Dashboard',
    icon: <BsSpeedometer2 />,
    type: "",
    path: '/',
  },
  {
    label: 'Company Settings',
    icon: <Settings />,
    type: "company",
    path: '/account-settings',
  },
  {
    label: 'Company Main Menu',
    icon: <Menu />,
    type: "company", // must have to enter type here for role "company" or "admin"
    childPath: ['/company-details', '/employees-details'], // must have to enter childpath here if item has children
    children: [
      {
        label: 'Details',
        icon: <DisplaySettings />,
        path: '/company-details'
      },
      {
        label: 'Employees',
        icon: <PermIdentity />,
        path: '/employees-details'
      },
    ]
  },
  {
    label: 'Companies',
    icon: <DynamicForm />,
    path: '/companies',
    type: "admin",// must have to enter type here for role  "company" or "admin"
  },
  {
    label: 'Vehicles',
    icon: <CarRepairTwoTone />,
    path: '/vehicles',
    type: "admin",// must have to enter type here for role "company" or "admin"
  },
  {
    label: 'Vehicle Management',
    icon: <CarRepairTwoTone />,
    path: '/vehicle-management',
    type: "admin",// must have to enter type here for role "company" or "admin"
  },
  {
    label: 'Bookings',
    icon: <CarRepairTwoTone />,
    path: '/bookings',
    type: "admin",// must have to enter type here for role "company" or "admin"
  },
  {
    label: 'Passengers',
    icon: <CarRepairTwoTone />,
    path: '/passengers',
    type: "admin",// must have to enter type here for role "company" or "admin"
  },
  {
    label: 'Employees',
    type: "admin",
    icon: <PermIdentity />,
    path: '/employees-details'
  },
]
export default Navigation