const CompaniesRoutes = {
  getCompanyRoles: "companies/roles",
  updateUser: "companies/updateuser",
  companyLocations: "companies/locations",
  registerEmployeeBulk: "companies/addBulkUsers",
  createCompany: "companies/add",
  getCompanies: "companies",
  getCompanyDetail: "companies/details",
  deleteCompany: "",
  updatedCompanyDetail: "companies/update",
};

export default CompaniesRoutes