import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '../Buttons/Button';

function SimpleDialog({ open, onClose, title, action, loading }) {
  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>{title}</DialogTitle>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, textAlign: 'center' }}>
        <PrimaryButton
          data-testId="yes-btn"
          title="Yes"
          loading={loading}
          onClick={() => action()}
        />
        <Box sx={{ mx: 0.5 }} />
        <SecondaryButton
          data-testId="cancel-btn"
          title="No"
          onClick={() => onClose()}
        />
      </Box>
    </Dialog>
  )
}

export default SimpleDialog