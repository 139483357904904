import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CloseRounded } from '@mui/icons-material';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { Error } from 'app/components/UI/Error';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster } from 'app/components/Toaster/Toaster';


function VehicleMakeDialog({ open, close, add, update, loading, updateData, vehicleTypes }) {

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const [vehicleTypeSelected, setVehicleTypeSelected] = useState('');

  // *For Submit Form
  const submitForm = async (formData) => {
    try {
      let obj = {
        type_id: vehicleTypeSelected,
        name: formData.make
      }
      if (updateData) {
        obj.id = updateData.id
        update(obj)
      } else {
        add(obj)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (updateData) {
      setValue("make", updateData.name)
      setVehicleTypeSelected(updateData.type_id)
    } else {
      setVehicleTypeSelected('')
    }
  }, [updateData])

  return (
    <Dialog open={open} fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}>
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Vehicle Make
        <IconButton onClick={() => { close(); reset() }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent >
        <Typography component={"form"} onSubmit={handleSubmit(submitForm)}>
          <FormControl size="small" fullWidth variant="outlined" sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
            <Select
              label={"Vehicle Type"}
              value={vehicleTypeSelected}
              sx={{ textAlign: "left" }}
              error={errors?.vehicleType?.message && true}
              {...register("vehicleType", {
                required: 'Please select vehicle type.',
              })}
            >
              {vehicleTypes?.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                  onClick={() => { setVehicleTypeSelected(item.id) }}
                >
                  {item.type}
                </MenuItem>
              ))}
            </Select>
            {errors?.vehicleType?.message && (
              <Error message={errors?.vehicleType?.message} />
            )}
          </FormControl>
          <InputField
            fullWidth={true}
            label="Make"
            error={errors?.make?.message}
            register={register("make", {
              required: 'Please enter vehicle make.'
            })
            }
          />
          <PrimaryButton
            data-testId="submit-btn"
            title="Submit"
            type="submit"
            loading={loading}
            fullWidth={true}
          />
        </Typography>

      </DialogContent>
    </Dialog>
  )
}

export default VehicleMakeDialog