import React, { Fragment, useState } from 'react'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { DeleteTwoTone, DirectionsCarFilledTwoTone, EditTwoTone, TwoWheelerTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import { Loading } from 'app/assets/Images'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import VehicleTypeDialog from 'app/components/Dialog/VehicleDialogs/VehicleTypeDialog'

function VehicleTypes({ getVehicleTypes, vehicleTypes }) {

  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  // *For Dialog Box
  const [openDialog, setOpenDialog] = useState(false);

  // *For Delete
  const [deleteId, setDeleteId] = useState();

  // *For Update Data
  const [updateData, setUpdateData] = useState();

  // *For Loading
  const [loading, setLoading] = useState(false);

  // *For Add Vehicle Type
  const createVehicleType = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.createVehicleType(obj)
      if (status === true && responseCode === 200) {
        getVehicleTypes()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Update Vehicle Type
  const updateVehicleType = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.updateVehicleType(obj)
      if (status === true && responseCode === 200) {
        getVehicleTypes()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Delete Type
  const deleteVehicleType = async () => {
    try {
      const { status, responseCode, message } = await VehiclesServices.deleteVehicleType(deleteId)
      if (status === true && responseCode === 200) {
        getVehicleTypes()
        SuccessToaster(message)
        setAlertDialog(false)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const onClose = () => {
    setUpdateData('')
    setOpenDialog(false)
  }

  return (
    <Fragment>

      {/* ======= Alert Dialog =====  */}
      <SimpleDialog open={alertDialog} onClose={() => setAlertDialog(false)} title={"Are you sure to remove this?"} action={() => deleteVehicleType()} />

      {/* ========== Vehicle Types Dialog ========== */}
      <VehicleTypeDialog
        open={openDialog}
        close={() => onClose()}
        add={(data) => createVehicleType(data)}
        update={(data) => updateVehicleType(data)}
        loading={loading}
        updateData={updateData}
      />

      {vehicleTypes.length > 0 ? (
        <Grid container spacing={2} >
          {vehicleTypes.map((element, index) => (
            <Grid item key={index} xs={3}>
              <Box
                sx={{
                  bgcolor: colors.primary, p: 2, borderRadius: "12px",
                  overflow: "hidden", position: "relative",
                  ":hover": {
                    ".roundBox": {
                      width: "100%",
                      borderRadius: "0%",
                      left: 0,
                      zIndex: 2,
                      opacity: 0.90
                    },
                    ".actionsIcon": {
                      display: "block",
                      transition: "all 1s ease"
                    }
                  }
                }}>
                <Box
                  className="roundBox"
                  sx={{
                    transition: "all 0.4s ease",
                    position: "absolute",
                    cursor: "pointer",
                    top: -6,
                    left: -30,
                    backgroundColor: colors.secondary,
                    borderRadius: "50%",
                    width: 100,
                    height: 110
                  }}
                />
                <Box className="actionsIcon"
                  sx={{
                    display: "none",
                    top: "50%", left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 999, position: "absolute",
                  }}>
                  <Tooltip title="Edit Vehicle" >
                    <IconButton onClick={() => { setUpdateData(element); setOpenDialog(true) }}>
                      <EditTwoTone sx={{ fontSize: "20px", color: colors.white }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Vehicle" >
                    <IconButton onClick={() => { setDeleteId(element.id); setAlertDialog(true) }} >
                      <DeleteTwoTone sx={{ fontSize: "20px", color: colors.white }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {element.type === "Motorcycle" ?
                    (<TwoWheelerTwoTone sx={{ zIndex: 1, color: colors.white }} />)
                    :
                    (<DirectionsCarFilledTwoTone sx={{ zIndex: 1, color: colors.white }} />)
                  }
                  <Typography variant="h5" fontWeight="bold" color="initial" sx={{
                    color: colors.white, zIndex: 1
                  }}>
                    {element.type}
                  </Typography>
                  <Typography variant="h4" fontWeight="bold" sx={{ color: colors.white, zIndex: 1, textAlign: "center" }}>
                    {element.max_seats}
                    <br />
                    <Typography variant="body1" sx={{ color: colors.white, zIndex: 1, fontSize: "12px" }}>
                      seats
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={1.5}>
            <Box
              onClick={() => setOpenDialog(true)}
              sx={{
                bgcolor: colors.primaryLight, p: 2, borderRadius: "12px", height: 0.65,
                display: "flex", alignItems: "center", justifyContent: "center",
                cursor: "pointer"
              }}>
              <BsPlusLg color={colors.primaryDark} size={35} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
          <Loading />
        </Box>
      )}

    </Fragment>
  )
}

export default VehicleTypes