import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import CompaniesServices from 'app/apis/Companies/CompaniesServices'
import colors from 'app/styles/colors'
import Layout from 'app/layout/Layout'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import CompanyForm from 'app/components/Forms/CompanyForm'

function AddCompany() {

  const navigate = useNavigate()

  // *For Loading
  const [loading, setLoading] = useState(false)

  // *For Create Company
  const createCompany = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await CompaniesServices.createCompany(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        navigate("/companies")
      }
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Layout>

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", }}>

        <Grid container alignItems="center" justifyContent="space-between" sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Add New Company
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Add New Company']} />
          </Grid>
        </Grid>

        <CompanyForm loading={loading} onSubmit={createCompany} />

      </Box>
    </Layout>
  )
}

export default AddCompany