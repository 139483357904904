import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { CloseRounded, DeleteTwoTone, SimCardDownloadTwoTone, UploadFileTwoTone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import useAuth from 'app/hooks/useAuth';
import CompaniesServices from 'app/apis/Companies/CompaniesServices';
import AddressesServices from 'app/apis/Addresses/AddressesServices';
import UsersServices from 'app/apis/Users/UsersServices';
import { ErrorToaster, SuccessToaster } from '../Toaster/Toaster';
import { InputField, PhoneInputField } from '../UI/TextField';
import { emailRegex } from 'app/utils';
import SelectField from '../UI/SelectFields';
import { PrimaryButton } from '../Buttons/Button';
import { Error } from '../UI/Error';




function AddNewEmployee({ handleClose, open, toggle, toggleFunction, selectedItem, getEmployees }) {


  // const classes = useStyles()
  // const inputRef = useRef()
  // const cnicRef = useRef()
  // const [selectedProfileImage, setSelectedProfileImage] = useState("");
  // const [selectedNicImage, setSelectedNicImage] = useState("");

  const { companyId, role } = useAuth()
  const radioRef = useRef()
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const [companiesList, setCompaniesList] = useState([])

  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [gender, setGender] = useState("")
  const [loading, setLoading] = useState(false)
  const [updatedCity, setUpdatedCity] = useState("")
  const [updatedAddress, setUpdatedAddress] = useState("")
  const [updatedCompany, setUpdatedCompany] = useState({})
  const [cities, setCities] = useState([]);
  const [allCompanyLocations, setAllCompanyLocations] = useState([]);


  // *For Profile Image change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setSelectedProfileImage(URL.createObjectURL(e.target.files[0]));

    }
  };
  // *For NIC Image change
  const imageNicChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setSelectedNicImage(URL.createObjectURL(e.target.files[0]));

    }
  };

  const cleanInputs = () => {
    // inputRef.current.value = ""
    // cnicRef.current.value = ""
    // setSelectedProfileImage("")
    // setSelectedNicImage("")
    setTelephoneNumber("")
    setUpdatedAddress("")
    setUpdatedCompany("")
    setUpdatedCity("")
    setGender("")
    setAllCompanyLocations([])
    setCities([])
    reset()
  }

  const handleTelephoneNumber = (value) => {
    setValue('telephone', value);
    setTelephoneNumber(value)
  }

  // *For add employee
  const addEmployeeData = async (formData) => {
    try {
      let obj = {
        name: formData.employeeName,
        email: formData.email,
        phone: formData.telephone,
        office_email: formData.officialEmail,
        gender: formData.gender,
        city_id: updatedCity.id,
        company_location_id: updatedAddress.id,
        department: formData.department,
        company_id: role === "admin" ? updatedCompany.id : companyId
      }
      const { status, responseCode, message } = await UsersServices.addEmployee(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        cleanInputs()
        handleClose()
        getEmployees()
      }

    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For update employee
  const updateEmployee = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        id: selectedItem.id,
        name: formData.employeeName,
        email: formData.email,
        phone: formData.telephone,
        office_email: formData.officialEmail,
        gender: gender,
        company_location_id: updatedAddress.id,
        department: formData.department,
        company_id: role === "admin" ? updatedCompany.id : companyId
      }
      const { status, responseCode, message } = await UsersServices.updateEmployee(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        cleanInputs()
        handleClose()
        getEmployees()
        toggleFunction()
      }

    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // for get companies
  const getCompanies = async () => {
    try {
      const { status, responseCode, message } = await CompaniesServices.getCompanies()
      if (status === true && responseCode === 200) {
        setCompaniesList(message.data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // for get cities
  const getCities = async () => {
    try {
      const { status, responseCode, message } = await AddressesServices.getCities()
      if (status === true && responseCode === 200) {
        setCities(message.data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // for get companies location
  const getCompanyLocations = async () => {
    try {
      if (companyId !== "null") {
        const { status, responseCode, data } = await CompaniesServices.companyLocations(companyId)
        if (status === true && responseCode === 200) {
          setAllCompanyLocations(data.locations)
        }
      }
      else {
        const { status, responseCode, data } = await CompaniesServices.companyLocations(updatedCompany?.id)
        if (status === true && responseCode === 200) {
          setAllCompanyLocations(data.locations)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }



  useEffect(() => {

    if (toggle) {
      setValue("employeeName", selectedItem.name)
      setValue("telephone", selectedItem.phone)
      setTelephoneNumber(selectedItem.phone)
      setValue("email", selectedItem.email)
      setValue("gender", selectedItem.gender)
      setGender(selectedItem.gender)
      setUpdatedCompany(selectedItem.company)
      setUpdatedAddress(selectedItem.company_address)
      setValue("department", selectedItem.department)
      setValue("officialEmail", selectedItem.office_email)
    }
    if (open) {
      // getCities()
      getCompanies()
      if (companyId !== "null") {
        getCompanyLocations()
      }
    }
  }, [toggle, open])


  useEffect(() => {

    if (Object.keys(updatedCompany).length) {
      getCompanyLocations()
    }
  }, [updatedCompany])


  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => { handleClose(); cleanInputs(); toggleFunction() }}
      sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {toggle ? "Edit Employee" : "Add New Employee"}
        <IconButton onClick={() => { handleClose(); cleanInputs(); toggleFunction() }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent >

        <Typography component={"form"} onSubmit={handleSubmit(toggle ? updateEmployee : addEmployeeData)}>
          <Grid container spacing={2} alignItems="center">

            {/* Company Name */}
            {role === "admin" &&
              <Grid item xs={12} md={6}>
                <SelectField
                  data={companiesList}
                  label="Company Name"
                  error={errors?.companyName?.message}
                  register={register("companyName", {
                    required: 'Please select company name.',
                  })}
                  stateValue={updatedCompany.name}
                  setState={(e) => setUpdatedCompany(e)} />
              </Grid>
            }
            {/* Full Name */}
            <Grid item xs={12} md={role === "admin" ? 6 : 12}>
              <InputField
                label="Full Name"
                error={errors?.employeeName?.message}
                register={register("employeeName", {
                  required: 'Please enter employee name.',
                })
                }
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} md={6}>
              <InputField
                label="Email"
                error={errors?.email?.message}
                register={register("email", {
                  required: 'Please enter your email.',

                  pattern: {
                    value: emailRegex,
                    message: 'Please enter a valid email address',
                  }
                })}
              />
            </Grid>
            {/* Optional Email */}
            <Grid item xs={12} md={6}>
              <InputField
                label="Official Email"
                error={errors?.officialEmail?.message}
                register={register("officialEmail", {
                  pattern: {
                    value: emailRegex,
                    message: 'Please enter a valid email address',
                  }
                })}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12}>
              <SelectField
                data={allCompanyLocations}
                label="Company Address"
                error={errors?.address?.message}
                register={register("address", {
                  required: 'Please select address.',
                })}
                stateValue={updatedAddress?.formatted_address}
                setState={(e) => setUpdatedAddress(e)} />
            </Grid>
            {/* City */}
            {/* <Grid item xs={12} md={4}>
              <SelectField
                data={cities}
                label="City"
                error={errors?.city?.message}
                register={register("city", {
                  required: 'Please select city.',
                })}
                stateValue={updatedCity.name}
                setState={(e) => setUpdatedCity(e)} />
            </Grid> */}
            {/* Telephone */}
            <Grid item xs={12} md={6}>
              <PhoneInputField
                error={errors?.telephone?.message}
                register={register("telephone", {
                  required: 'Please enter your telephone number.'
                })}
                onChange={(e) => {
                  handleTelephoneNumber(e)
                }}
                value={telephoneNumber}
              />
            </Grid>
            {/* Department Name */}
            <Grid item xs={12} md={6}>
              <InputField
                label="Department"
                error={errors?.department?.message}
                register={register("department", {
                  required: 'Please enter department name.',
                })
                }
              />
            </Grid>
            {/* Gender */}
            <Grid item xs={12} md={6}>
              <FormControl >
                <Typography variant="body1" sx={{ fontSize: '14px', display: 'inline-flex', fontWeight: 'bold', }} >
                  Gender
                </Typography>
                <RadioGroup defaultValue={gender} row >
                  <FormControlLabel
                    value={"male"}
                    control={<Radio />}
                    label={"Male"}
                    sx={{ '& .MuiTypography-root': { fontSize: '14px' } }}
                    {...register("gender", {
                      required: 'Select any gender'
                    })}
                  />
                  <FormControlLabel
                    value={"female"}
                    control={<Radio />}
                    label={"Female"}
                    sx={{ '& .MuiTypography-root': { fontSize: '14px' } }}
                    {...register("gender", {
                      required: 'Select any gender'
                    })}
                  />
                </RadioGroup>
                {errors?.gender?.message && (
                  <Error message={errors?.gender?.message} />
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* <PrimaryButton
            type="submit"
            loading={loading}
            myStyle={{ mt: 1, borderRadius: "12px" }} fullWidth={true}
          >
            {}
          </PrimaryButton> */}
          <PrimaryButton
            data-testId="save-btn"
            title={toggle ? "Edit Employee" : "Add Employee"}
            type="submit"
            loading={loading}
          />

        </Typography>

      </DialogContent>
    </Dialog>
  )
}

export default AddNewEmployee