import React, { Fragment, useState } from 'react'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import { Loading } from 'app/assets/Images'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import VehicleModelDialog from 'app/components/Dialog/VehicleDialogs/VehicleModelDialog'

function VehicleModels({ getVehicleModels, vehicleModels, vehicleMakes }) {

  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  // *For Delete
  const [deleteId, setDeleteId] = useState();

  // *For Dialog Box
  const [openDialog, setOpenDialog] = useState(false);

  // *For Update Data
  const [updateData, setUpdateData] = useState();

  // *For Loading
  const [loading, setLoading] = useState(false);

  // *For Add Vehicle Model
  const createVehicleModel = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.createVehicleModel(obj)
      if (status === true && responseCode === 200) {
        getVehicleModels()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Update Vehicle Model
  const updateVehicleModel = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.updateVehicleModel(obj)
      if (status === true && responseCode === 200) {
        getVehicleModels()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Delete Models
  const deleteVehicleModels = async () => {
    try {
      const { status, responseCode, message } = await VehiclesServices.deleteVehicleModel(deleteId)
      if (status === true && responseCode === 200) {
        getVehicleModels()
        SuccessToaster(message)
        setAlertDialog(false)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const onClose = () => {
    setUpdateData('')
    setOpenDialog(false)
  }

  return (
    <Fragment>

      {/* ======= Alert Dialog =====  */}
      <SimpleDialog open={alertDialog} onClose={() => setAlertDialog(false)} title={"Are you sure to remove this?"} action={() => deleteVehicleModels()} />

      {/* ========== Vehicle Model Dialog ========== */}
      <VehicleModelDialog
        open={openDialog}
        close={() => onClose()}
        add={(data) => createVehicleModel(data)}
        update={(data) => updateVehicleModel(data)}
        loading={loading}
        updateData={updateData}
        vehicleMakes={vehicleMakes}
      />

      {vehicleModels.length > 0 ? (
        <Grid container spacing={2} >
          {vehicleModels.map((element, index) => (
            <Grid item key={index} xs={3}>
              <Box sx={{
                bgcolor: colors.primary, p: 2, borderRadius: "12px",
                overflow: "hidden", position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>

                <Box
                  sx={{
                    transition: "all 0.4s ease",
                    position: "absolute",
                    cursor: "pointer",
                    top: -6,
                    right: -30,
                    backgroundColor: colors.secondary,
                    borderRadius: "50%",
                    width: 100,
                    height: 120
                  }}
                />

                <Box>
                  <Typography variant="h6" fontWeight="bold" noWrap sx={{ maxWidth: 120, color: colors.white, zIndex: 1 }}>
                    {element.name}
                  </Typography>

                  <Typography variant="body2" color="initial" sx={{
                    color: colors.white, fontSize: 12
                  }}>
                    Class: {element.engine_class}
                  </Typography>

                  <Typography variant="body2" color="initial" sx={{
                    color: colors.white, fontSize: 12
                  }}>
                    CC: {element.engine_cc}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex", flexDirection: "column"
                  }}>
                  <Tooltip title="Edit" >
                    <IconButton onClick={() => { setUpdateData(element); setOpenDialog(true) }} >
                      <EditTwoTone sx={{ fontSize: "20px", color: colors.white }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" >
                    <IconButton onClick={() => { setDeleteId(element.id); setAlertDialog(true) }} >
                      <DeleteTwoTone sx={{ fontSize: "20px", color: colors.white }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={1.5}>
            <Box
              onClick={() => setOpenDialog(true)}
              sx={{
                bgcolor: colors.primaryLight, p: 2, borderRadius: "12px", height: 0.7,
                display: "flex", alignItems: "center", justifyContent: "center",
                cursor: "pointer"
              }}>
              <BsPlusLg color={colors.primaryDark} size={35} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
          <Loading />
        </Box>
      )}

    </Fragment>
  )
}

export default VehicleModels