import { Chip } from "@mui/material";
import colors from "app/styles/colors";

export const StatusChip = ({ status, label }) => {

  return (
    <Chip
      label={`${status ? (label ? label.active : 'active') : (label ? label.inactive : 'inActive')}`}
      sx={{
        bgcolor: status ? colors.success + 26 : colors.danger + 26,
        color: status ? colors.success : colors.danger,
        height: 26,
        textTransform: 'capitalize'
      }}
    />
  );
}