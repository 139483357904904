import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { AddBusinessTwoTone, DeleteTwoTone, KeyboardArrowLeft, KeyboardArrowRight, VisibilityTwoTone } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import CompaniesServices from 'app/apis/Companies/CompaniesServices'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import Layout from 'app/layout/Layout'
import colors from 'app/styles/colors'
import { Loading } from 'app/assets/Images'
import CompanyFilter from 'app/components/Filters/CompanyFilter'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'


// *Table Pagination Actions Component
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// *Custom Tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});

function Companies() {

  const navigate = useNavigate()

  const tableHead = ['#ID', 'Name', 'Phone No', 'Email', 'Employees', 'NTN', 'Industry Type', 'Website', 'Status', 'Actions']

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // *For Loader
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);

  // *For Dialog Box
  const [deleteDialog, setDeleteDialog] = useState(false);

  // *For Company
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');

  // *For Status
  const [isActive, setIsActive] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *For Get Companies
  const getCompanies = async () => {
    setLoader(true)
    try {
      const { status, responseCode, data } = await CompaniesServices.getCompanies()
      if (status === true && responseCode === 200) {
        setCompanies(data.companies)
        setDefaultStatus(data.companies)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  // *For Update Employee
  const updateCompanyStatus = async (id) => {
    try {
      const index = isActive.indexOf(id);
      const newStatus = [...isActive];
      let active
      if (index === -1) {
        newStatus.push(id);
        active = true
      } else {
        newStatus.splice(index, 1);
        active = false
      }
      setIsActive(newStatus)
      let obj = {
        id: id,
        is_active: active
      }
      const { status, responseCode, message } = await CompaniesServices.updatedCompanyDetail(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Default Status
  const setDefaultStatus = (data) => {
    try {
      let status = []
      data.forEach(element => {
        if (element.is_active) {
          status.push(element.id)
        }
      });
      setIsActive(status)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Delete Company
  const deleteCompany = async () => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await CompaniesServices.deleteCompany(companyId)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        getCompanies()
        setDeleteDialog(false)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Layout>

      {/* ========== Delete Dialog ========== */}
      <SimpleDialog open={deleteDialog} onClose={() => setDeleteDialog(false)} title={'Are you sure to remove this?'} action={deleteCompany} loading={loading} />

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Companies List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Companies']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>

            {/* ========== Filter ========== */}
            {/* <CompanyFilter /> */}

            <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", mt: 2 }}>
              <Box
                onClick={() => navigate("/company/add-company")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 1,
                  cursor: "pointer",
                  color: colors.primary, bgcolor: colors.primaryLight,
                }}>
                <AddBusinessTwoTone sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px" }} >Add New Company</Typography>
              </Box>
            </Box>

            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0 ? companies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : companies).map((row, index) => (
                    <TableRow key={index} >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell >
                        <Typography variant="body2" color="initial" noWrap>{row.name ?? "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.owner_contact ?? "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTooltip title={row.email} arrow>
                          <Typography variant="body2" color="initial" noWrap
                            sx={{ cursor: "default", maxWidth: 170 }}>{row.email ?? "-"}</Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.employee_strength ?? 0}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.ntn ?? "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.type_industry ?? "no industry type"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.website ?? "-"}</Typography>
                      </TableCell>
                      <TableCell >
                        <Switch
                          size="small"
                          checked={isActive.indexOf(row.id) !== -1}
                          onClick={() => updateCompanyStatus(row.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          <Tooltip title="Details" >
                            <IconButton onClick={() => navigate(`/company-information/${row.id}`)} >
                              <VisibilityTwoTone color="secondary" sx={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" >
                            <IconButton onClick={() => { setCompanyId(row.id); setDeleteDialog(true) }}>
                              <DeleteTwoTone color="error" sx={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      colSpan={15}
                      count={companies.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

          </Fragment>
        )}
      </Box>
    </Layout>
  )
}

export default Companies