import { get, post, patch, deleted } from 'app/apis';
import UsersRoutes from './Users.Routes';

const UsersServices = {
  getUserByID: async (id) => {
    const data = await get(UsersRoutes.getUserByID + `/?id=${id}`);
    return data;
  },
  addEmployeeBulk: async (obj) => {
    const data = await post(UsersRoutes.addEmployeeBulk, obj);
    return data;
  },
  resetPassword: async (obj) => {
    const data = await patch(UsersRoutes.resetPassword, obj);
    return data;
  },
  addEmployee: async (obj) => {
    const data = await post(UsersRoutes.addEmployee, obj);
    return data;
  },
  getEmployees: async (companyId) => {
    const data = await get(UsersRoutes.getEmployee + `/?company_id=${companyId}`);
    return data;
  },
  getEmployeesAdmin: async () => {
    const data = await get(UsersRoutes.getEmployee);
    return data;
  },
  updateEmployee: async (obj) => {
    const data = await patch(UsersRoutes.updateEmployee, obj);
    return data;
  },
}

export default UsersServices