import { get, post, patch, deleted } from 'app/apis';
import CompaniesRoutes from './Companies.Routes';

const CompaniesServices = {
  createCompany: async (obj) => {
    const data = await post(CompaniesRoutes.createCompany, obj);
    return data;
  },
  getCompanies: async () => {
    const data = await get(CompaniesRoutes.getCompanies);
    return data;
  },
  getCompanyRoles: async () => {
    const data = await get(CompaniesRoutes.getCompanyRoles);
    return data;
  },
  getCompanyDetail: async (id) => {
    const data = await get(CompaniesRoutes.getCompanyDetail + `?company_id=${id}`);
    return data;
  },
  updatedCompanyDetail: async (obj) => {
    const data = await patch(CompaniesRoutes.updatedCompanyDetail, obj);
    return data;
  },
  deleteCompany: async (id) => {
    const data = await deleted(CompaniesRoutes.deleteCompany + `?company_id=${id}`);
    return data;
  },
  updateUser: async (obj) => {
    const data = await patch(CompaniesRoutes.updateUser, obj);
    return data;
  },
  registerEmployeeBulk: async (obj) => {
    const data = await post(CompaniesRoutes.registerEmployeeBulk, obj);
    return data;
  },
  companyLocations: async (companyId) => {
    const data = await get(CompaniesRoutes.companyLocations + `?company_id=${companyId}`);
    return data;
  },
}

export default CompaniesServices