import { get, post, patch, deleted } from 'app/apis';
import PassengersRoutes from './Passengers.Routes';

const PassengersServices = {
  getPassengers: async () => {
    const data = await get(PassengersRoutes.getPassengers);
    return data;
  },
}

export default PassengersServices