import React, { useState } from 'react'
import { Box, Collapse, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { FilterAltTwoTone, Search } from '@mui/icons-material'
import colors from 'app/styles/colors';
import { OutlineInputField } from '../UI/TextField';
import SelectField from '../UI/SelectFields';
import { cities, empStrengthRange, industryTypes } from 'app/utils/CustomData';
import { PrimaryButton } from '../Buttons/Button';


function CompanyFilter() {

  const [filterCollapse, setFilterCollapse] = useState(false);

  const [updatedCity, setUpdatedCity] = useState("");
  const [empStrength, setEmpStrength] = useState("");
  const [updatedIndustryType, setUpdatedIndustryType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight="bold" color="initial">Filters</Typography>
        <IconButton onClick={() => setFilterCollapse(!filterCollapse)} color="primary" sx={{ bgcolor: colors.primaryLight }}>
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse} >
        <Grid container spacing={2} alignItems="center" justifyContent={"flex-end"}>
          <Grid item xs={6}>
            <OutlineInputField
              label="Search"
              endAdornment={
                <InputAdornment position="end" sx={{ color: colors.primary }}>
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              data={industryTypes}
              label="Industry"
              stateValue={empStrength}
              setState={(e) => setEmpStrength(e)}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              data={cities}
              label="City"
              stateValue={updatedCity}
              setState={(e) => setUpdatedCity(e)}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              data={empStrengthRange}
              label="Employee Strength"
              stateValue={updatedIndustryType}
              setState={(e) => setUpdatedIndustryType(e)}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              data={["Active", "De-active"]}
              label="Status"
              stateValue={selectedStatus}
              setState={(e) => setSelectedStatus(e)}
            />
          </Grid>
          <Grid item xs={3}>
            <PrimaryButton
              data-testId="filter-btn"
              title="Apply Filter"
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default CompanyFilter