import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import { CloseRounded, DeleteOutlined, DeleteTwoTone, SimCardDownloadTwoTone, UploadFileTwoTone } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import colors from 'app/styles/colors';
import useAuth from 'app/hooks/useAuth';
import { PrimaryButton } from '../Buttons/Button';
import { Error } from '../UI/Error';
import { ErrorToaster, SuccessToaster } from '../Toaster/Toaster';
import UsersServices from 'app/apis/Users/UsersServices';
import SelectField from '../UI/SelectFields';


const useStyles = makeStyles({
  inputLabel: {
    padding: "10px 0px",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 8,
    border: `1px dashed #E7E7E7`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputFilled: {
    backgroundColor: colors.primaryLight
  }
})

function AddByBulk({ handleClose, open }) {


  const { companyId, role } = useAuth()
  const classes = useStyles()
  const inputRef = useRef()
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [fileUploading, setFileUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedCSVFile, setUploadedCSVFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [companiesList, setCompaniesList] = useState([])
  const [updatedCompany, setUpdatedCompany] = useState([])

  const uploadCsvFile = (csvFile) => {
    setFileUploading(true)
    setUploadedCSVFile(csvFile.target.files[0])
    setUploadedFileName(csvFile.target.files[0].name)
    setCsvError(false)
    setTimeout(() => {
      setFileUploading(false)
    }, 800);
  }

  const cleanInputs = () => {
    inputRef.current.value = ""
    setUploadedFileName("")
    setUploadedCSVFile("")
    setUpdatedCompany([])
  }


  // *For Add by bulk
  const registerEmployeeBulk = async () => {
    setLoading(true)
    try {

      if (uploadedCSVFile) {
        const formData = new FormData();
        formData.append("company_id", JSON.parse(companyId) ? companyId : updatedCompany.id)
        formData.append("document", uploadedCSVFile, uploadedCSVFile.name)

        const { status, responseCode, message } = await UsersServices.addEmployeeBulk(formData)
        if (status === true && responseCode === 200) {
          SuccessToaster(message)
          cleanInputs()
          handleClose()
        }
      }
      else {
        setCsvError(true)
      }

    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  // for get companies
  const getCompanies = async () => {
    try {
      const { status, responseCode, message } = await UsersServices.getCompanies()
      if (status === true && responseCode === 200) {
        setCompaniesList(message.data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (role === "admin") {
      getCompanies()
    }
  }, [open])


  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => { handleClose(); cleanInputs() }}
      sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Add By Bulk
        <IconButton onClick={() => { handleClose(); cleanInputs() }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(registerEmployeeBulk)}>
          <Grid container spacing={2} >
            {/* Company Name */}
            {role === "admin" &&
              <Grid item xs={12}>
                <SelectField
                  data={companiesList}
                  label="Select Company"
                  error={errors?.company?.message}
                  register={register("company", {
                    required: 'Please select company name.',
                  })}
                  stateValue={updatedCompany.name}
                  setState={(e) => setUpdatedCompany(e)} />
              </Grid>
            }
            <Grid item xs={6}>
              <label
                className={classes.inputLabel}
              >

                <a href="https://takseem.mangotech-api.com/csvData/Bulk_Add_Users.csv" style={{ textDecoration: "none" }} download>
                  <Box sx={{ textAlign: "center" }}>
                    <SimCardDownloadTwoTone sx={{ fontSize: "80px", color: colors.primary }} />
                    <Typography variant="body2" color="initial">
                      Download base template
                    </Typography>
                  </Box>
                </a>
              </label>
            </Grid>
            <Grid item xs={6}>
              <label
                className={clsx(classes.inputLabel, uploadedFileName && classes.inputFilled)}
                htmlFor='csv-file-upload'
              >
                {fileUploading ?
                  (<CircularProgress size={32} color="secondary" />) : (

                    <Box sx={{ textAlign: "center" }}>
                      <UploadFileTwoTone sx={{ fontSize: "80px", color: uploadedFileName ? colors.primaryDark : colors.primary }} />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="initial">
                          {uploadedFileName ? uploadedFileName : "Upload through csv file"}
                        </Typography>
                        {uploadedFileName &&
                          <DeleteTwoTone
                            onClick={(e) => { e.preventDefault(); cleanInputs() }}
                            sx={{
                              fontSize: 16,
                              cursor: "pointer",
                              ml: 1.5,
                              color: colors.danger
                            }} />
                        }
                      </Box>
                    </Box>

                  )}

              </label>
              <input
                ref={inputRef}
                disabled={fileUploading}
                accept=".csv , .xlsx"
                style={{ display: 'none' }}
                id="csv-file-upload"
                type="file"
                onChange={uploadCsvFile}
              />
              {csvError && (
                <Error message={"File is required"} />
              )}
            </Grid>
          </Grid>
          {/* <PrimaryButton
            loading={loading}
            type="submit"
            myStyle={{ mt: 6, borderRadius: "12px" }} fullWidth={true}
          >Add</PrimaryButton> */}
          <PrimaryButton
            data-testId="save-btn"
            title={"Add"}
            type="submit"
            loading={loading}
          />
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default AddByBulk