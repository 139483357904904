import { useState } from "react";

function useProvideAuth() {

  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);

  const login = (data) => {
    localStorage.setItem('jwt', data.token)
    localStorage.setItem('role', data.role)
    localStorage.setItem('name', data.name)
    localStorage.setItem('email', data.email)
    localStorage.setItem('id', data.company_id)
    localStorage.setItem('code', data.company_code)
    setUser(data.token)
    setRole(data.role)
    setName(data.name)
    setEmail(data.email)
    setCompanyId(data.company_id)
    setCompanyCode(data.company_id)
  };

  const logout = () => {
    localStorage.clear()
    setUser(null)
    setRole(null)
    setName(null)
    setEmail(null)
    setCompanyId(null)
    setCompanyCode(null)
  };

  // *For Verify Token
  const verify = () => {
    const token = localStorage.getItem('jwt')
    const role = localStorage.getItem('role')
    const name = localStorage.getItem('name')
    const email = localStorage.getItem('email')
    const id = localStorage.getItem('id')
    const code = localStorage.getItem('code')
    setUser(token)
    setRole(role)
    setName(name)
    setEmail(email)
    setCompanyId(id)
    setCompanyCode(code)

  };

  return {
    user,
    role,
    name,
    email,
    companyId,
    companyCode,
    login,
    logout,
    verify,
  };
}

export default useProvideAuth;