import React, { useEffect, useState } from 'react'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { BuildCircleTwoTone, ColorLensTwoTone, DirectionsCarFilledTwoTone, PersonOutlineTwoTone } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'
import Layout from 'app/layout/Layout'
import colors from 'app/styles/colors'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import VehicleTypes from './Manage/VehicleTypes'
import VehicleColors from './Manage/VehicleColors'
import VehicleMakes from './Manage/VehicleMakes'
import VehicleModels from './Manage/VehicleModels'
import { ErrorToaster } from 'app/components/Toaster/Toaster'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'

function VehicleManagement() {

  // *For Tab Lists
  const tabList = [
    { icon: <PersonOutlineTwoTone />, title: 'Types' },
    { icon: <BuildCircleTwoTone />, title: 'Make' },
    { icon: <DirectionsCarFilledTwoTone />, title: 'Model' },
    { icon: <ColorLensTwoTone />, title: 'Colors' },
  ]

  // *For Change Tabs
  const [currentTab, setCurrentTab] = useState(0);

  // *For Vehicle Types
  const [vehicleTypes, setVehicleTypes] = useState([]);

  // *For Vehicle Colors
  const [vehicleColors, setVehicleColors] = useState([]);

  // *For Vehicle Makes
  const [vehicleMakes, setVehicleMakes] = useState([]);

  // *For Vehicle Models
  const [vehicleModels, setVehicleModels] = useState([]);

  // *For Get Vehicle Types
  const getVehicleTypes = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleTypes()
      if (status === true && responseCode === 200) {
        setVehicleTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Vehicle Colors
  const getVehicleColors = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleColors()
      if (status === true && responseCode === 200) {
        setVehicleColors(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Vehicle Makes
  const getVehicleMakes = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleMakes()
      if (status === true && responseCode === 200) {
        setVehicleMakes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Vehicle Models
  const getVehicleModels = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleModels()
      if (status === true && responseCode === 200) {
        setVehicleModels(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getVehicleTypes()
    getVehicleColors()
    getVehicleMakes()
    getVehicleModels()
  }, []);

  return (
    <Layout>

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}>

        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Vehicles Management</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Vehicles Management']} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>

          {/* ========== Tabs ========== */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} sx={{ '.Mui-selected': { fontWeight: 'bold' } }}>
              {tabList.map((item, index) => (
                <Tab
                  key={index}
                  icon={item.icon}
                  iconPosition="start"
                  label={item.title}
                  sx={{
                    fontWeight: 100,
                    textTransform: "capitalize",
                    minHeight: "auto"
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <SwipeableViews index={currentTab}>

            {/* ========== Types ========== */}
            <VehicleTypes getVehicleTypes={getVehicleTypes} vehicleTypes={vehicleTypes} />

            {/* ========== Makes ========== */}
            <VehicleMakes getVehicleMakes={getVehicleMakes} vehicleMakes={vehicleMakes} vehicleTypes={vehicleTypes} />

            {/* ========== Models ========== */}
            <VehicleModels getVehicleModels={getVehicleModels} vehicleModels={vehicleModels} vehicleMakes={vehicleMakes} />

            {/* ========== Colors ========== */}
            <VehicleColors getVehicleColors={getVehicleColors} vehicleColors={vehicleColors} />

          </SwipeableViews>

        </Box>

      </Box>
    </Layout>
  )
}

export default VehicleManagement