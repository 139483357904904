const VehiclesRoutes = {
  getVehicles: "/companies/vehicles",

  // *Vehicle Types
  getVehicleTypes: "vehicles/getTypes",
  createVehicleType: "vehicles/type/add",
  updateVehicleType: "vehicles/type/update",
  deleteVehicleType: "vehicles/type/delete/",

  // *Vehicle Colors
  getVehicleColors: "vehicles/getColors",
  createVehicleColor: "vehicles/createColor",
  updateVehicleColor: "vehicles/updateColor",
  deleteVehicleColor: "vehicles/deleteColor/",

  // *Vehicle Makes
  getVehicleMakes: "vehicles/getMakes",
  createVehicleMake: "vehicles/createMake",
  updateVehicleMake: "vehicles/updateMake",
  deleteVehicleMake: "vehicles/deleteMake/",

  // *Vehicle Models
  getVehicleModels: "vehicles/getModels",
  createVehicleModel: "vehicles/createModel",
  updateVehicleModel: "vehicles/updateModel",
  deleteVehicleModel: "vehicles/deleteModel/",
};

export default VehiclesRoutes