import { get, post, patch, deleted } from 'app/apis';
import VehiclesRoutes from './Vehicles.Routes';

const VehiclesServices = {
  getVehicles: async () => {
    const data = await get(VehiclesRoutes.getVehicles);
    return data;
  },

  // *Vehicle Types
  getVehicleTypes: async () => {
    const data = await get(VehiclesRoutes.getVehicleTypes);
    return data;
  },
  createVehicleType: async (obj) => {
    const data = await post(VehiclesRoutes.createVehicleType, obj);
    return data;
  },
  deleteVehicleType: async (id) => {
    const data = await deleted(VehiclesRoutes.deleteVehicleType + `?id=${id}`);
    return data;
  },
  updateVehicleType: async (obj) => {
    const data = await patch(VehiclesRoutes.updateVehicleType, obj);
    return data;
  },

  // *Vehicle Types Colors
  getVehicleColors: async () => {
    const data = await get(VehiclesRoutes.getVehicleColors);
    return data;
  },
  createVehicleColor: async (obj) => {
    const data = await post(VehiclesRoutes.createVehicleColor, obj);
    return data;
  },
  updateVehicleColor: async (obj) => {
    const data = await patch(VehiclesRoutes.updateVehicleColor, obj);
    return data;
  },
  deleteVehicleColor: async (name) => {
    const data = await deleted(VehiclesRoutes.deleteVehicleColor + `?color=${name}`);
    return data;
  },

  // *Vehicle Makes
  getVehicleMakes: async () => {
    const data = await get(VehiclesRoutes.getVehicleMakes);
    return data;
  },
  createVehicleMake: async (obj) => {
    const data = await post(VehiclesRoutes.createVehicleMake, obj);
    return data;
  },
  updateVehicleMake: async (obj) => {
    const data = await patch(VehiclesRoutes.updateVehicleMake, obj);
    return data;
  },
  deleteVehicleMake: async (id) => {
    const data = await deleted(VehiclesRoutes.deleteVehicleMake + `?id=${id}`);
    return data;
  },

  // *Vehicle Models
  getVehicleModels: async () => {
    const data = await get(VehiclesRoutes.getVehicleModels);
    return data;
  },
  createVehicleModel: async (obj) => {
    const data = await post(VehiclesRoutes.createVehicleModel, obj);
    return data;
  },
  updateVehicleModel: async (obj) => {
    const data = await patch(VehiclesRoutes.updateVehicleModel, obj);
    return data;
  },
  deleteVehicleModel: async (id) => {
    const data = await deleted(VehiclesRoutes.deleteVehicleModel + `?id=${id}`);
    return data;
  },
}

export default VehiclesServices