import moment from "moment";

// *Current Year
export const CurrentYear = () => {
  let date = new Date();
  const year = date.getFullYear();
  return year
}

// *Current Date
export const CurrentDate = () => {
  let date = new Date();
  const currentDate = moment(date).format('MM/DD/YYYY');
  return currentDate
}

// *Date Format
export const getDate = (date) => {
  const newDate = moment(date).format('DD/MM/YYYY')
  return newDate
}

// *For Minutes into Hours
export const minuteIntoHours = (mins) => {
  var h = mins / 60 | 0,
    m = mins % 60 | 0;
  return moment.utc().hours(h).minutes(m).format("hh:mm A");
}

// *Days Difference
export const getDateDifference = (startDate, endDate) => {
  const newStartDate = moment(startDate).format('YYYY-MM-DD')
  const newEndDate = moment(endDate).format('YYYY-MM-DD')
  let a = moment(newEndDate);
  let b = moment(newStartDate);
  let diff = a.diff(b, 'days')
  return diff
}

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// *Cnic Regex
export const cnicRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/

// *Hex Color Regex
export const hexColorRegex = /^#([0-9a-fA-F]{3}){1,2}$/


